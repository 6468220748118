export const Issue2 = {
  home: {
    title: 'ISSUE 2 - AUTUMN 2024',
    intro:
      "Ralph Issue 2, where we go to Nashville with multi Grammy-nominated singer/songwriter Tayla Parx, do a scarecrow fashion feature with British Vogue editor Giles Hattersley, go to the brilliant Lord of the Rings show 'You Shall Not Yass', speak to J-Pop band f5ve, review goth cocktails and cheap perfumes, lie down with stand up Cat Cohen, look at amazing band  T-shirts, deep dive on South Asian hip-hop, check out the art of Naoshima Island and loads more.",
    buynow: {
      text: 'Ralph in your hands',
      url: 'https://www.mmslondon.co.uk/shop/p/ralphmagazineissu2'
    },
    support: {
      text: 'Ralph on your screen',
      url: '/masthead/#issue2'
    },
    issue: {
      img: 'issue_2_highres.png',
      alt: 'Ralph Issue 2'
    }
  },
  masthead: {
    id: 'issue2',
    contributors: {
      title: 'Contributors',
      list: [
        {
          name: 'Claudia Alexander',
          target: '_blank',
          url: 'https://www.instagram.com/cluedinwithclaud/'
        },
        {
          name: 'Guvna B',
          target: '_blank',
          url: 'https://www.instagram.com/guvnab/'
        },
        {
          name: 'James Brown',
          target: '_blank',
          url: 'https://www.instagram.com/jamesjamesbrown/'
        },
        {
          name: 'Corinne Cumming',
          target: '_blank',
          url: 'https://www.instagram.com/capturedbycorinne/'
        },
        { name: 'Tej Datar', target: '_blank', url: 'https://www.instagram.com/ttejdatar/?hl=en' },
        {
          name: 'Jeff Definitely',
          target: '_blank',
          url: 'https://www.instagram.com/jeff.definitely/'
        },
        {
          name: 'Stephanie Furtun',
          target: '_blank',
          url: 'https://www.instagram.com/cinephanie/'
        },
        {
          name: 'Giles Hattersley',
          target: '_blank',
          url: 'https://www.instagram.com/gileshattersley/'
        },
        {
          name: 'Kenya Iman',
          target: '_blank',
          url: 'https://www.instagram.com/kenyaiman.style/'
        },
        {
          name: 'Radhika Iyengar',
          target: '_blank',
          url: 'https://www.instagram.com/radhika_iy/'
        },
        {
          name: 'H. N. James ',
          target: '_blank',
          url: 'ttps://www.instagram.com/punkrockfoto/'
        },
        {
          name: 'Tara Joshi',
          target: '_blank',
          url: 'https://x.com/tara_dwmd'
        },
        {
          name: 'Andy Knowles',
          target: '_blank',
          url: 'https://www.instagram.com/_fine_bone_china/'
        },
        {
          name: 'Scott Lyon',
          target: '_self',
          url: '/masthead'
        },
        {
          name: 'Gav Murphy',
          target: '_blank',
          url: 'https://www.instagram.com/cymrogav/'
        },
        {
          name: 'Popcorn Pirate',
          target: '_self',
          url: '/masthead'
        },
        {
          name: 'Em Prové ',
          target: '_blank',
          url: 'https://www.instagram.com/studio__emprove/'
        },
        {
          name: 'DeWayne Richards',
          target: '_blank',
          url: 'https://www.instagram.com/iamdrbeauty/'
        },
        {
          name: 'Ben Smoke',
          target: '_blank',
          url: 'https://www.instagram.com/bencsmoke/'
        },
        {
          name: 'Dak Stiga',
          target: '_blank',
          url: 'https://www.instagram.com/dak_draws/'
        },
        {
          name: 'Stephanie Unger',
          target: '_blank',
          url: 'https://www.instagram.com/stephanieunger/'
        },
        {
          name: 'Muir Vidler',
          target: '_blank',
          url: 'https://www.instagram.com/muirvidler/'
        },
        {
          name: 'Hannah Wong',
          target: '_blank',
          url: 'https://www.instagram.com/hannahwong.zip/'
        }
      ]
    },
    features: {
      title: 'Links',
      list: [
        {
          name: 'Tayla Parx',
          target: '_blank',
          url: 'https://www.instagram.com/taylaparx/'
        },
        {
          name: 'South Asian hip-hop',
          target: '_blank',
          url: 'https://www.youtube.com/channel/UCogOqwiP6Ho4zB4nbNkJQTw'
        },
        {
          name: 'f5ve',
          target: '_blank',
          url: 'https://www.instagram.com/f5ve_official/'
        },
        {
          name: 'Cat Cohen',
          target: '_blank',
          url: 'https://www.instagram.com/cat_cohen/'
        },
        {
          name: 'You Shall Not Yass',
          target: '_blank',
          url: 'https://www.instagram.com/youshallnotyass/'
        },
        {
          name: "Guvna B's playlist",
          target: '_blank',
          url: 'https://open.spotify.com/playlist/3o35tYP2mr7lUfnUdyUhoS?si=fwPeBDLoSKSvhmuGzXRbwA'
        },
        {
          name: 'Band T-Shirts',
          target: '_blank',
          url: 'https://www.instagram.com/_fine_bone_china/'
        }
      ]
    },
    promotions: [
      {
        title: 'Order your Ralph socks',
        img: 'promotion_socks.png',
        url: 'https://forms.gle/Gz56PRdARYF5L5hc8'
      },
      {
        title: "Guvna's Greatest Hits",
        img: 'promotion_guvna.png',
        url: 'https://open.spotify.com/playlist/3o35tYP2mr7lUfnUdyUhoS?si=fwPeBDLoSKSvhmuGzXRbwA'
      }
    ]
  }
}
